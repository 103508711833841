import './style.css';
import * as THREE from 'three';
import { PointLight } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

const canvas = document.querySelector('canvas.webgl');

const scene = new THREE.Scene();
const scenePath = require('./assets/Construction-Sign.glb');

const loader = new GLTFLoader();
loader.load(scenePath, function(gltf) {
    scene.add(gltf.scene);
}, undefined, function (error) {
    console.error(error);
});

const ambientLight = new THREE.AmbientLight(0x707040);
scene.add(ambientLight);

const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100);
camera.position.x = 5;
camera.position.y = 3;
camera.position.z = -3;

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
});
renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

const controls = new OrbitControls(camera, renderer.domElement);

controls.target.y = 0.8;

controls.minDistance = 5;
controls.maxDistance = 12;
controls.maxPolarAngle = Math.PI / 2;

controls.enablePan = false;

controls.enableDamping = true;
controls.dampingFactor = 0.08;

// Blinkers
const blinkLightL1 = new THREE.PointLight(0xBBBB77, 100, 0.25);
blinkLightL1.position.x = 0.1;
blinkLightL1.position.y = 2.41;
blinkLightL1.position.z = 1.26;

const blinkLightL2 = new THREE.PointLight(0xBBBB77, 100, 0.25);
blinkLightL2.position.x = -0.1;
blinkLightL2.position.y = 2.41;
blinkLightL2.position.z = 1.26;

scene.add(blinkLightL1);
scene.add(blinkLightL2);

const blinkLightR1 = new THREE.PointLight(0xBBBB77, 100, 0.25);
blinkLightR1.position.x = 0.1;
blinkLightR1.position.y = 2.41;
blinkLightR1.position.z = -1.26;

const blinkLightR2 = new THREE.PointLight(0xBBBB77, 100, 0.25);
blinkLightR2.position.x = -0.1;
blinkLightR2.position.y = 2.41;
blinkLightR2.position.z = -1.26;

scene.add(blinkLightR1);
scene.add(blinkLightR2);

////////////
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
};

window.addEventListener('resize', () => {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

////////////
const clock = new THREE.Clock();

const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    renderer.setClearColor(0x2e2e3e, 1);
    renderer.clear(true, false, false);

    const timing = 50 * Math.sin(elapsedTime * 4);

    blinkLightL1.intensity = Math.max(0, timing);
    blinkLightL2.intensity = Math.max(0, timing);
    blinkLightR1.intensity = Math.max(0, -timing);
    blinkLightR2.intensity = Math.max(0, -timing);

    renderer.render(scene, camera);

    window.requestAnimationFrame(tick);

    controls.update();
};

tick();